const at_least_one_required_identifier_fields = ['email', 'work_email', 'cell_phone', 'phone_number', 'iterable_user_uuid',
    'global_uuid', 'uuids', 'unknown_uuid'];

const form_input_types_string = 'input, select, textarea, tel, email, url, password, date, datetime-local, month, time, week, number, checkbox, radio';

const allowed_iterable_h_hash_key_names = [
    'h_iterable_iterable_user_id',
    'h_iterable_email',
    'h_iterable_cell_phone',
    'h_iterable_phone_number',
    'h_iterable_global_uuid'
];

const allow_boolean_only = ['sms_optin', 'email_unsubscribe','is_eligible_for_express_lane','spanish'];

const white_list_outgoing_person_fields = [
    'prefix', 'title', 'suffix',
    'sms_optin', 'email_unsubscribe',
    'email',
    'first_name', 'last_name', 'full_name', 'first_initial', 'last_initial', 'middle_name', 'middle_initial',
    'cell_phone', 'phone_number', 'primary_cell_phone', 'primary_phone_number', 'work_phone_number', 'landline',
    'address', 'address_2', 'city', 'state', 'state_code', 'zip', 'zip_4', 'county', 'country', 'country_code', 'formatted_address',
    'route', 'street_number', 'neighborhood', 'administrative_area_level_2', 'administrative_area_level_1',
    'occupation','employer', 'job_title',
    'employer_address', 'employer_address_2', 'employer_formatted_address', 'employer_city', 'employer_state', 'employer_state_code',
    'employer_zip', 'employer_zip_4', 'employer_country', 'employer_country_code',
    'gender',
    'is_eligible_for_express_lane',
    'birth_year', 'birth_month', 'birth_day', 'birth_date', 'locality', 'address_timezone','address_utc_offset',
    'subscribedMessageTypeIds', 'userListIds', 'unsubscribedChannelIds', 'unsubscribedMessageTypeIds', 'emailListIds',
    'spanish'
]

// OPENAI i think for checkboxes they coiuld be default labels?
const user_data_form_field_placeholders = {
    'prefix': 'Prefix',
    'title': 'Title',
    'suffix': 'Suffix',

    'email_unsubscribe': 'Unsubscribe from emails',
    'sms_optin': 'Opt in to SMS',
    'email': 'Email',
    'first_name': 'First Name',
    'last_name': 'Last Name',
    'full_name': 'Full Name',
    'first_initial': 'First Initial',

    'last_initial': 'Last Initial',
    'middle_name': 'Middle Name',
    'middle_initial': 'Middle Initial',
    'cell_phone': 'Cell Phone',
    'phone_number': 'Phone Number',
    'primary_cell_phone': 'Primary Cell Phone',
    'primary_phone_number': 'Primary Phone Number',
    'work_phone_number': 'Work Phone Number',
    'landline': 'Landline',
    'address': 'Address',
    'address_2': 'Address 2',
    'city': 'City',
    'state': 'State',
    'state_code': 'State Code',
    'zip': 'Zip',
    'zip_4': 'Zip 4',
    'county': 'County',
    'country': 'Country',
    'country_code': 'Country Code',
    'route': 'Route',
    'street_number': 'Street Number',
    'neighborhood': 'Neighborhood',

    'administrative_area_level_2': 'Administrative Area Level 2',
    'administrative_area_level_1': 'Administrative Area Level 1',
    'occupation': 'Occupation',
    'employer': 'Employer',
    'job_title': 'Job Title',
    'employer_address': 'Employer Address',
    'employer_address_2': 'Employer Address 2',
    'employer_formatted_address': 'Employer Formatted Address',
    'employer_city': 'Employer City',
    'employer_state': 'Employer State',
    'employer_state_code': 'Employer State Code',
    'employer_zip': 'Employer Zip',
    'employer_zip_4': 'Employer Zip 4',
    'employer_country': 'Employer Country',
    'employer_country_code': 'Employer Country Code',
    'gender':'Gender',
    'birth_year':'Birth Year',
    'birth_month':'Birth Month',
    'birth_day':'Birth Day',
    'birth_date':'Birth Date',
    'spanish':'I Speak Spanish',
}

const html_input_autocomplete_types_by_user_data_key_name = {
    'first_name': 'given-name',
    'middle_name': 'additional-name',
    'last_name': 'family-name',
    'full_name': 'name',
    'prefix': 'honorific-prefix',
    'suffix': 'honorific-suffix',
    'cell_phone': 'tel',
    'phone_number': 'tel',
    'primary_cell_phone': 'tel',
    'primary_phone_number': 'tel',
    'work_phone_number': 'tel',
    'landline': 'tel',
    'address': 'street-address',
    'address_2': 'address-line2',
    'city': 'address-level2',
    'state': 'address-level1',
    'zip': 'postal-code',
    'country': 'country-name',
    'country_code': 'country',
    'email': 'email',
    'occupation': 'organization-title',
    'employer': 'organization',
    'birth_year': 'bday-year',
    'birth_month': 'bday-month',
    'birth_day': 'bday-day',
    'birth_date': 'bday',
    'gender': 'sex',
};


// These are slightly different from the whitelist. these are rendered signup forms so like no unsub list arrays etc
// OPENAI make sure that we have complete overlap with user_data_form_field_placeholders
const user_data_form_field_types = {
    'prefix': 'text',
    'title': 'text',
    'suffix': 'text',
    'sms_optin': 'checkbox',
    'email_unsubscribe': 'checkbox',
    'email': 'email',
    'first_name': 'text',
    'last_name': 'text',
    'full_name': 'text',
    'first_initial': 'text',
    'last_initial': 'text',
    'middle_name': 'text',
    'middle_initial': 'text',
    'cell_phone': 'tel',
    'phone_number': 'tel',
    'primary_cell_phone': 'tel',
    'primary_phone_number': 'tel',
    'work_phone_number': 'tel',
    'landline': 'tel',
    'address': 'text',
    'address_2': 'text',
    'city': 'text',
    'state': 'text',
    'state_code': 'text',
    'zip': 'text',
    'zip_4': 'text',
    'county': 'text',
    'country': 'text',
    'country_code': 'text',
    'route': 'text',
    'street_number': 'text',
    'neighborhood': 'text',
    'administrative_area_level_2': 'text',
    'administrative_area_level_1': 'text',
    'occupation': 'text',
    'employer': 'text',
    'job_title': 'text',
    'employer_address': 'text',
    'employer_address_2': 'text',
    'employer_formatted_address': 'text',
    'employer_city': 'text',
    'employer_state': 'text',
    'employer_state_code': 'text',
    'employer_zip': 'text',
    'employer_zip_4': 'text',
    'employer_country': 'text',
    'employer_country_code': 'text',
    'gender': 'text',
    'birth_year': 'text',
    'birth_month': 'text',
    'birth_day': 'text',
    'birth_date': 'text',
    'spanish': 'checkbox',
    'formatted_address': 'text',
}

const white_list_incoming_person_fields = [
    'global_uuid', 'unknown_uuid',
    'prefix', 'title', 'suffix',
    'sms_optin', 'email_unsubscribe',
    'email', 'provided_email', 'emails', 'email_lower_md5', 'email_upper_md5', 'email_lower_sha256', 'email_upper_sha256',
    'first_name', 'last_name', 'full_name', 'first_initial', 'last_initial', 'middle_name', 'middle_initial',
    'cell_phone', 'phone_number', 'primary_cell_phone', 'primary_phone_number', 'work_phone_number', 'landline',
    'address', 'address_2', 'city', 'state', 'state_code', 'zip', 'zip_4', 'county', 'country', 'country_code', 'formatted_address',
    'route', 'street_number', 'neighborhood', 'google_place_id', 'administrative_area_level_2', 'administrative_area_level_1',
    'occupation', 'provided_occupation', 'provided_employer', 'employer', 'job_title',
    'employer_address', 'employer_address_2', 'employer_formatted_address', 'employer_city', 'employer_state', 'employer_state_code',
    'employer_zip', 'employer_zip_4', 'employer_country', 'employer_country_code', 'employer_address_google_place_id',
    'gender', 'gender_modeled',
    'birth_year', 'birth_month', 'birth_day', 'birth_date', 'locality', 'address_timezone','address_utc_offset',
    'subscribedMessageTypeIds', 'userListIds', 'unsubscribedChannelIds', 'unsubscribedMessageTypeIds', 'emailListIds',
    'spanish',
    'lifetime_donation_amount', 'last_donation_amount', 'last_donation_date', 'first_donation_date',
    'average_donation_amount', 'is_eligible_for_express_lane', 'total_donation_count', 'double_last_donation'
]

const array_like_url_params = ['userListIds', 'subscribedMessageTypeIds', 'unsubscribedChannelIds', 'unsubscribedMessageTypeIds', 'emailListIds']

const endpoints_that_require_user_or_email_under_user_object = ["commerce/trackPurchase", "commerce/updateCart", "users/byUserId", "users/getByEmail"]

const endpoints_that_require_phoneNumber = ["verify/sms/begin", "verify/sms/check"]

const endpoints_that_require_recipientEmail_or_recipientUserId = ["email/target", "inApp/target", "push/target", "sms/target", "webPush/target"]

const endpoints_narrow_allow_list = [
    "users/update", "users/updateSubscriptions",
    "commerce/trackPurchase", "auth/jwts/invalidate",
    "events/trackWebPushClick", "events/track",
    "users/registerDeviceToken", "users/registerBrowserToken"]
const endpoints_that_require_user_or_email = ["users/update", "users/updateSubscriptions", "users/forget", "users/unforget", "auth/jwts/invalidate",
    "events/trackWebPushClick", "events/trackPushOpen", "events/track", "embedded-messaging/events/session", "embedded-messaging/events/click", "embedded-messaging/events/received",
    "events/inAppConsume", "events/trackInAppClick", "events/trackInAppClose", "events/trackInAppDelivery", "events/trackInAppOpen",
    "email/cancel", "inApp/cancel", "push/cancel", "sms/cancel", "webPush/cancel", "workflows/triggerWorkflow",
    "users/disableDevice", "users/registerDeviceToken", "users/registerBrowserToken",
]


export {
    at_least_one_required_identifier_fields,
    allowed_iterable_h_hash_key_names,
    white_list_incoming_person_fields,
    array_like_url_params,
    white_list_outgoing_person_fields,
    allow_boolean_only,
    endpoints_that_require_user_or_email_under_user_object,
    endpoints_that_require_phoneNumber,
    endpoints_that_require_recipientEmail_or_recipientUserId,
    endpoints_narrow_allow_list,
    endpoints_that_require_user_or_email,
    form_input_types_string,
    user_data_form_field_types,
    user_data_form_field_placeholders,
    html_input_autocomplete_types_by_user_data_key_name
}