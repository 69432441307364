export function get_boolean_query_param(param) {
    // If no param return null
    if (!param) {return null;}
    const value = new URLSearchParams(window.location.search).get(param);
    // if no value return null  
    if (!value) {return null;}
    return parse_boolean_falsy_types(value);
}

export function is_valid_url(test_url) {
    try {
        new URL(test_url);
        return true;
    } catch (error) {
        return false;
    }
}

export function parse_int_or_return_null(value) {
    const parsed = parseInt(parseFloat(value));
    return isNaN(parsed) ? null : parsed;
}

export function parse_float_or_return_null(value) {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? null : parsed;

}

export function detect_parse_url_param_types(the_value) {
    if (is_likely_matching_boolean_falsy_string(the_value) === true) {
        return parse_boolean_falsy_types(the_value);
    } else if (!isNaN(the_value)) {
        // if has decimal parse as float
        if (the_value.includes('.')) {
            return parse_float_or_return_null(the_value);
        } else {
            return parse_int_or_return_null(the_value);
        }
    } else {
        return the_value;
    }
}

export function is_likely_matching_boolean_falsy_string(the_value) {
    const truthy_values = ['true', 't', '1', 'yes', 'on'];
    const falsy_values = ['false', 'f', '0', 'no', 'off'];

    if (typeof the_value === 'string') {
        if (truthy_values.includes(the_value.toLowerCase()) || falsy_values.includes(the_value.toLowerCase())) {
            return true;
        }
    }
    return false;

}

export function parse_boolean_falsy_types(the_value) {
    const truthy_values = ['true', 't', '1', 'yes', 'on'];
    const falsy_values = ['false', 'f', '0', 'no', 'off'];

    if (typeof the_value === 'string') {
        if (truthy_values.includes(the_value.toLowerCase())) {
            return true;
        } else if (falsy_values.includes(the_value.toLowerCase())) {
            return false;
        }
    } else if (typeof the_value === 'boolean') {
        return the_value;
    } else if (typeof the_value === 'number') {
        return the_value !== 0;
    } else {
        console.error('parse_boolean_falsy_types: value is not a string, boolean, or number', the_value);
        return null;
    }
}


export async function fetch_with_timeout(resource, options = {}) {
    const { timeout = 2000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(id);

    return response;
}

export function remove_empty_values_from_object(data, depth = 0, max_depth = 10) {
    if (depth > max_depth) {
        console.log(`Soft error maximum recursion depth of ${max_depth} exceeded`);
        return data;
    }

    if (Array.isArray(data)) {
        return data
            .filter(item =>
                item !== undefined &&
                item !== null &&
                item !== '' &&
                !(Array.isArray(item) && item.length === 0) &&
                !(item.constructor === Object && Object.keys(item).length === 0)
            )
            .map(item => remove_empty_values_from_object(item, depth + 1, max_depth));
    } else if (data !== null && typeof data === 'object') {
        return Object.entries(data).reduce((acc, [key, value]) => {
            if (
                value !== undefined &&
                value !== null &&
                value !== '' &&
                !(Array.isArray(value) && value.length === 0) &&
                !(value.constructor === Object && Object.keys(value).length === 0)
            ) {
                acc[key] = remove_empty_values_from_object(value, depth + 1, max_depth);
            }
            return acc;
        }, {});
    }
    return data;
}

export function return_valid_forms_domain(forms_domain) {
    // Default domain for smiling-garden
    if (!forms_domain) {
        // TODO this wont work not live
        forms_domain = 'https://action-two-smiling-garden-126003.uc.r.appspot.com';
    }
    // Remove trailing slash if present
    if (forms_domain.endsWith('/')) {
        forms_domain = forms_domain.slice(0, -1);
    }
    // Ensure non-beta domains start with 'https://'
    if (!forms_domain.startsWith('https://')) {
        console.error('ERROR: forms_domain must start with https://');
        return null;
    }
    return forms_domain;
}

export function extract_array_values_from_url_parameter(the_parameter_value, data_hub_instance = null) {
    try {
        if (!the_parameter_value) {
            console.log('debug no parameter value');
            return [];
        }

        // If it is a string, split by commas to convert to array
        if (typeof the_parameter_value === 'string') {
            if (the_parameter_value.startsWith('[') && the_parameter_value.endsWith(']')) {
                the_parameter_value = the_parameter_value.slice(1, -1).trim();
            }

            // Check if the string has commas
            if (!the_parameter_value.includes(',')) {
                return [the_parameter_value.trim()];
            }

            const values = the_parameter_value.split(',');
            return values.map(value => value.trim());
        } else if (Array.isArray(the_parameter_value)) {
            return the_parameter_value;
        } else {
            console.error('Error extracting array values from url parameter: parameter is not a string or array', the_parameter_value);
            if (data_hub_instance && data_hub_instance.error_function) {
                return data_hub_instance.error_function('Error extracting array values from url parameter');
            }
        }
    } catch (error) {
        console.error('Error extracting array values from url parameter', error);
        if (data_hub_instance && data_hub_instance.error_function) {
            return data_hub_instance.error_function('Error extracting array values from url parameter');
        }
    }
    return [];
}

// COLORS and tailwind css generators
// So can pass a primary 600 value and generate full 100 -> 900 gradient
export function hex_to_hsl(hex) {
    if (!hex) {
        console.error("hex_to_hsl missing hex");
        return null;
    }
    if (typeof hex !== 'string') {
        console.error("hex_to_hsl hex must be a string");
        return null;
    }

    hex = hex.replace(/^#/, '');
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);

    r /= 255;
    g /= 255;
    b /= 255;

    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return [h * 360, s * 100, l * 100];
}

export function hsl_to_hex(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    const to_hex = x => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${to_hex(r)}${to_hex(g)}${to_hex(b)}`;
}

export function generate_gradient_stops(main_hex_color) {
    if (!main_hex_color) {
        console.error("generate_gradient_stops missing main_hex_color");
        return {};
    }

    // Must be a valid color hex color code (3 or 6 digits)
    if (typeof main_hex_color !== 'string' || !main_hex_color.match(/^#([0-9A-F]{3}|[0-9A-F]{6})$/i)) {
        console.error("generate_gradient_stops main_hex_color must be a valid hex color code");
        return {};
    }

    // Normalize 3-digit hex codes to 6 digits
    if (main_hex_color.length === 4) {
        main_hex_color = '#' + main_hex_color.slice(1).split('').map(char => char + char).join('');
    }

    const hsl = hex_to_hsl(main_hex_color);
    const stops = {};

    for (let i = 1; i <= 9; i++) {
        const lightness = 100 - (i * 10); // Reverse the lightness
        stops[(i * 100).toString()] = hsl_to_hex(hsl[0], hsl[1], lightness);
    }

    // Number 600 should be the main_hex_color
    stops['600'] = main_hex_color;

    return stops;
}


export function find_closest_shade(colors, target) {
    if (!colors || !target) {
        console.error("find_closest_shade missing vars");
        return null;
    }
    const keys = Object.keys(colors).map(Number).sort((a, b) => a - b);
    return keys.reduce((prev, curr) => Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev).toString();
}

export function to_kebab_case(str) {
    if (!str) {
        return null;
    }
    if (typeof str !== 'string') {
        return null;
    }
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase().replace(/_/g, '-');
}
