// shared_form_ux_functions.js
import { form_input_types_string } from '../helpers/static_vars';
import {parse_boolean_falsy_types} from '../helpers/index';

function add_submit_button_loading_state(form_container, error_function = window.data_hub_error_function) {
    if (!form_container) {
        return error_function('No form container');
    }

    const submit_button = form_container.querySelectorAll('.data_hub_submit_form');
    if (!submit_button) {
        return error_function('No submit button');
    }

    // loop through all and add back .hidden classes
    submit_button.forEach(button => {
        if (button) {
            const button_text = button.querySelector('.button-text');
            if (!button_text) {
                return
            }

            const loader = button.querySelector('.loader');
            if (!loader) {
                return
            }

            button_text.classList.add('hidden');
            loader.classList.remove('hidden');
            
        }
    });
}

function remove_submit_button_loading_state(form_container, error_function = window.data_hub_error_function) {

    const submit_button = form_container.querySelectorAll('.data_hub_submit_form');
    if (!submit_button) {
        return error_function('No submit button');
    }
   
    // loop through all and add back .hidden classes
    submit_button.forEach(button => {
        if (button) {
            const button_text = button.querySelector('.button-text');
            if (!button_text) {
                return
            }

            const loader = button.querySelector('.loader');
            if (!loader) {
                return
            }

            button_text.classList.remove('hidden');
            loader.classList.add('hidden');
        }
    });
}

function show_error_popover(internal_error_message="Show error popover function is missing messages params", external_error_message="Error submitting your request. Refresh page to try again or contact support.") {
            
    const close_error_popover = (event=null) => {
        if (event) {
            event.preventDefault();
        }

        const error_popover = document.querySelector('.error_popover');
        if (error_popover) {
            error_popover.classList.add('hidden');
        }
    };
    
    console.error(internal_error_message)
    const error_popover = document.querySelector('.error_popover');
    if (error_popover) {
        error_popover.classList.remove('hidden');
        error_popover.querySelector('span.error_message').textContent = external_error_message;
        error_popover.addEventListener('click', close_error_popover);
    
        setTimeout(() => {
            close_error_popover({ preventDefault: () => {} });
        }, 5000);
    }
};


function add_form_loading_overlay(form_container=document) {
    //show form overlay divs
    form_container.querySelectorAll('.data_hub-form-overlay').forEach(overlay => overlay.classList.remove('hidden'));
}

function remove_form_loading_overlay(form_container=document) {
    // any overlayy divs that are hidden
    form_container.querySelectorAll('.data_hub-form-overlay').forEach(overlay => overlay.classList.add('hidden'));
}

function add_form_input_loading_state(form_container = document, prefill_input_selector=form_input_types_string) {
    //form_input_types_string is a list of all input elements
    // [data-allow-data_hub-prefill="true"] is a data attribute that allows the form to be prefilled
    form_container.querySelectorAll(prefill_input_selector).forEach(field => {
        field.classList.add('input-loading');
    });
}

function remove_form_input_loading_state(form_container=document, prefill_input_selector=form_input_types_string) {
    //form_input_types_string
    form_container.querySelectorAll(prefill_input_selector).forEach(field => {
        field.classList.remove('input-loading');
    });
}

// Defaults to only the pre-filled user data. so dont reset other form data
function reset_form(event, form_container, prefill_input_selector='[data-allow-data_hub-prefill="true"]', reset_button_selector='.data_hub_reset_form') {
    event.preventDefault();
    const elements = form_container.querySelectorAll(prefill_input_selector);
    Array.from(elements).forEach(element => {
        // TODO maybe dont do this?
        if (['checkbox', 'radio'].includes(element.type)) {
            element.checked = false;
        } else 
        if (['text', 'textarea', 'email', 'tel', 'password', 'url', 'hidden', 'number', 'date', 'datetime-local', 'month', 'time', 'week'].includes(element.type)) {
            element.value = '';
        } else if (element.tagName === 'SELECT') {
            element.value = element.options[0].value;
        } else {
            console.error('Unsupported input type:', element.type, element);
        }
    });

    const reset_button = form_container.querySelector(reset_button_selector);
    if (reset_button) {
        reset_button.classList.add('hidden');
    }
}

function if_has_user_data_show_reset_button(
    form_container, 
    reset_button_selector='.data_hub_reset_form',
    prefill_input_selector='[data-allow-data_hub-prefill="true"]',
    user_data=window.data_hub?.user_data|| {}
) {
    const reset_button = form_container.querySelector(reset_button_selector);
    if (reset_button) {
        reset_button.onclick = (event) => {
            event.preventDefault();
            user_data = {};
            reset_form(event, form_container, prefill_input_selector);
        };
        
        reset_button.classList.remove('hidden');
        
    }
}

function fill_element_with_data(element, key, value) {
    if (element.tagName === 'INPUT') {
        if (['checkbox', 'radio'].includes(element.type)) {
            element.checked = parse_boolean_falsy_types(value);
        } else if (['text', 'email', 'tel', 'password', 'hidden', 'date', 'datetime-local', 'month', 'time', 'week'].includes(element.type)) {
            element.value = value;
        } else if (element.type === 'url') {
            is_valid_url(value) ? element.value = value : console.error('prefill_form invalid url:', key, value);
        } else if (element.type === 'number') {
            element.value = parseFloat(value);
        } else {
            console.error('prefill_form input type not handled:', element.type, key, value);
        }
    } else if (element.tagName === 'SELECT' || element.tagName === 'TEXTAREA') {
        element.value = value;
    } else {
        console.error('prefill_form non-form element:', element.tagName, key, value);
    }
}

function prefill_form(
    form_container,
    user_data=window.data_hub?.user_data || {},
    prefill_input_selector='[data-allow-data_hub-prefill="true"]',
    reset_button_selector='.data_hub_reset_form' 
) {
    if (!window.prefilled_forms) {
        window.prefilled_forms = new Set();
    }

    if (window.prefilled_forms.has(form_container)) {
        console.log('DEBUG: this form container has already been prefilled ', form_container);
        return;
    };

    const elements = form_container.querySelectorAll(prefill_input_selector);

    if (!elements) {
        return;
    }

    let found_at_least_one_prefill = false;

    elements.forEach(element => {
        const key = element.id || element.name;

        if (user_data && user_data.hasOwnProperty(key)) {
            // only if not null / empty string
            if (user_data[key] !== null && user_data[key] !== '') {
                fill_element_with_data(element, key, user_data[key]);
                found_at_least_one_prefill = true;
            }
        }
    });

    window.prefilled_forms.add(form_container);

    // show a reset button if there is at least one prefill
    if (found_at_least_one_prefill) {
        if_has_user_data_show_reset_button(form_container, reset_button_selector, prefill_input_selector, user_data);
    }

    remove_form_input_loading_state(form_container);
    remove_form_loading_overlay(form_container);
}


export {

    add_form_input_loading_state,
    remove_form_input_loading_state,

    add_form_loading_overlay,
    remove_form_loading_overlay,

    add_submit_button_loading_state,
    remove_submit_button_loading_state,

    prefill_form,

    show_error_popover
}
