import { get_boolean_query_param, parse_int_or_return_null, extract_array_values_from_url_parameter } from '../helpers';
import { at_least_one_required_identifier_fields } from '../helpers/static_vars';

export class subscriptions_preferences_form {
    constructor(config = {}, data_hub_instance) {
        this.is_unsubscribe_page = config.is_unsubscribe_page || get_boolean_query_param('unsubscribe') || false;
        this.iterable_message_types = [];
        this.data_hub = data_hub_instance; // Ref the data_hub instance for local
    }

    static update_subscription_box_classes(box, is_checked) {
        if (is_checked) {
            box.classList.add('checked', 'shadow-lg', 'subscription_active', 'border-green-700');
            box.classList.remove('shadow-sm', 'border-grey-brand-color-200');
        } else {
            box.classList.add('shadow-sm', 'border-grey-brand-color-200');
            box.classList.remove('checked', 'shadow-lg', 'subscription_active', 'border-green-700');
        }
    }

    async get_iterable_message_types() {
        if (!this.data_hub.forms_domain) {
            console.error('ERROR: get_iterable_message_types: No forms domain found');
            this.send_data_hub_error('No forms domain found');
            return;
        }
        try {
            // TODO live wtf i have no idea why is fucking fucked fucker
            const channels_api_url = `${this.data_hub.forms_domain}/api/get/iterable_message_types?cache_bust=${Date.now()}`;
            const response = await fetch(channels_api_url);
            this.iterable_message_types = await response.json();
        } catch (error) {
            console.error('ERROR: get_iterable_message_types:', error);
            this.send_data_hub_error('Cant get iterable message types');
        }
    }

    send_data_hub_error(internal_error_message="Send data_hub error", external_error_message='Error submitting your request. Refresh page to try again or contact support.') {
        if (this.data_hub && this.data_hub.error_function) {
            this.data_hub.error_function(internal_error_message,external_error_message);
        } else {
            console.error(internal_error_message,external_error_message);
        }
    }

    update_subscription_checkboxes_state_from_user_data(form_container) {

        if (!this.data_hub.user_data) return;

        if (this.data_hub.user_data.subscribedMessageTypeIds) {

            this.data_hub.user_data.subscribedMessageTypeIds.forEach(id => {

                const subscription_box = form_container.querySelector(`.subscription-box[data-message-type-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = true;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, checkbox.checked);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        console.error('no checkbox in this subscriptions box');
                    }
                    
                }
            });
        }

        if (this.data_hub.user_data.unsubscribedMessageTypeIds) {

            this.data_hub.user_data.unsubscribedMessageTypeIds.forEach(id => {
                const subscription_box = form_container.querySelector(`.subscription-box[data-message-type-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = false;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, checkbox.checked);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        console.error('no checkbox in this subscriptions box');
                    }
                }
            });
        }

        if (this.data_hub.user_data.unsubscribedChannelIds) {
            this.data_hub.user_data.unsubscribedChannelIds.forEach(id => {
                const subscription_box = form_container.querySelector(`.subscription-box[data-channel-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = false;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, checkbox.checked);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        console.error('no checkbox in this subscriptions box');
                    }
                }
            });
        }

        if (this.data_hub.user_data.emailListIds) {
            this.data_hub.user_data.emailListIds.forEach(id => {
                const subscription_box = form_container.querySelector(`.subscription-box[data-email-list-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = true;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, checkbox.checked);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        console.error('no checkbox in this subscriptions box');
                    }
                }
            });
        }

        if (this.data_hub.user_data.userListIds) {
            this.data_hub.user_data.userListIds.forEach(id => {
                const subscription_box = form_container.querySelector(`.subscription-box[data-user-list-id="${id}"]`);
                if (subscription_box) {
                    const checkbox = subscription_box.querySelector('.checkbox');
                    if (checkbox) {
                        checkbox.checked = true;
                        subscriptions_preferences_form.update_subscription_box_classes(subscription_box, checkbox.checked);
                        this.update_checkbox_label(checkbox); // Update label on initialization
                    } else {
                        console.error('no checkbox in this subscriptions box');
                    }
                }
            });
        }
    }

    check_or_uncheck_all_subscription_checkboxes(form_container, check_status = true) {
        form_container.querySelectorAll('.subscription-box .checkbox').forEach(checkbox => {
            const subscription_box = checkbox.closest('.subscription-box');
            checkbox.checked = check_status;
            if (subscription_box) {
                subscriptions_preferences_form.update_subscription_box_classes(subscription_box, checkbox.checked);
                this.update_checkbox_label(checkbox); // Update label on initialization
                this.update_user_data_iterable_subscription_arrays(checkbox); // Update user data
            }
        });
    }

    update_user_data_iterable_subscription_arrays(checkbox) {

        let message_type_id = checkbox.dataset.referencesMessageTypeId || null;
        let email_list_id = checkbox.dataset.emailListId || null;
        let user_list_id = checkbox.dataset.userListId || null;
        let channel_id = checkbox.dataset.channelId || null;

        if (message_type_id) { message_type_id = parse_int_or_return_null(message_type_id); }
        if (email_list_id) { email_list_id = parse_int_or_return_null(email_list_id); }
        if (user_list_id) { user_list_id = parse_int_or_return_null(user_list_id); }
        if (channel_id) { channel_id = parse_int_or_return_null(channel_id); }

        if (!this.data_hub.user_data.subscribedMessageTypeIds) {
            this.data_hub.user_data.subscribedMessageTypeIds = [];
        }

        if (!this.data_hub.user_data.unsubscribedMessageTypeIds) {
            this.data_hub.user_data.unsubscribedMessageTypeIds = [];
        }

        if (!this.data_hub.user_data.emailListIds) {
            this.data_hub.user_data.emailListIds = [];
        }

        if (!this.data_hub.user_data.userListIds) {
            this.data_hub.user_data.userListIds = [];
        }

        if (!this.data_hub.user_data.unsubscribedChannelIds) {
            this.data_hub.user_data.unsubscribedChannelIds = [];
        }

        if (!this.data_hub.user_data.subscribedChannelIds) {
            this.data_hub.user_data.subscribedChannelIds = [];
        }

        if (checkbox.checked) {
            if (message_type_id !== null) {
                this.data_hub.user_data.unsubscribedMessageTypeIds = this.data_hub.user_data.unsubscribedMessageTypeIds.filter(id => id !== message_type_id);
                if (!this.data_hub.user_data.subscribedMessageTypeIds.includes(message_type_id)) {
                    this.data_hub.user_data.subscribedMessageTypeIds.push(message_type_id);
                }
            }

            if (email_list_id !== null) {
                if (!this.data_hub.user_data.emailListIds.includes(email_list_id)) {
                    this.data_hub.user_data.emailListIds.push(email_list_id);
                }
            }

            if (user_list_id !== null) {
                if (!this.data_hub.user_data.userListIds.includes(user_list_id)) {
                    this.data_hub.user_data.userListIds.push(user_list_id);
                }
            }

            if (channel_id !== null) {
                this.data_hub.user_data.unsubscribedChannelIds = this.data_hub.user_data.unsubscribedChannelIds.filter(id => id !== channel_id);
                if (!this.data_hub.user_data.subscribedChannelIds.includes(channel_id)) {
                    this.data_hub.user_data.subscribedChannelIds.push(channel_id);
                }
            }
        } else {
            // Only message type and channel types have specific unsub arrays. Email and user lists are only subscribed or not
            if (message_type_id !== null) {
                this.data_hub.user_data.subscribedMessageTypeIds = this.data_hub.user_data.subscribedMessageTypeIds.filter(id => id !== message_type_id);
                if (!this.data_hub.user_data.unsubscribedMessageTypeIds.includes(message_type_id)) {
                    this.data_hub.user_data.unsubscribedMessageTypeIds.push(message_type_id);
                }
            }

            if (channel_id !== null) {
                this.data_hub.user_data.subscribedChannelIds = this.data_hub.user_data.subscribedChannelIds.filter(id => id !== channel_id);
                if (!this.data_hub.user_data.unsubscribedChannelIds.includes(channel_id)) {
                    this.data_hub.user_data.unsubscribedChannelIds.push(channel_id);
                }
            }

            // If it exists in emailListIds array remove it
            if (email_list_id !== null) {
                this.data_hub.user_data.emailListIds = this.data_hub.user_data.emailListIds.filter(id => id !== email_list_id);
            }

            // If it exists in userListIds array remove it
            if (user_list_id !== null) {
                this.data_hub.user_data.userListIds = this.data_hub.user_data.userListIds.filter(id => id !== user_list_id);
            }
        }

    }

    toggle_checkbox(event) {
        event.preventDefault();
        event.stopPropagation();
    
        const target = event.target;

        // if the target is the preview button type skip
        if (target.classList.contains('preview-email-button')) {
            // get the data-email-preview-url attribute value
            const previewUrl = target.getAttribute('data-email-preview-url');

            if (previewUrl) {
                this.open_email_preview_modal(previewUrl);
            } else {
                console.error('No preview url found');
                return
            }

        } else {

            const subscriptionBox = target.closest('.subscription-box');
        
            if (!subscriptionBox) {
                console.error('No subscription box found');
                return this.data_hub.error_function('No subscription box found');
            }
        
            if (target.type === 'checkbox') {

                // Update user data and checkbox label if clicked directly
                this.update_user_data_iterable_subscription_arrays(target);
                subscriptions_preferences_form.update_subscription_box_classes(subscriptionBox, target.checked);
                this.update_checkbox_label(target);
        
                const the_desired_checked_state = target.checked;

                // Get a new reference to the checkbox
                const reselect_checkbox = subscriptionBox.querySelector('.checkbox');
                if (reselect_checkbox) {
                    this.manually_change_checked_from_scratch(reselect_checkbox, the_desired_checked_state);
                } else {
                    console.error('No reselect_checkbox found in subscriptionBox');
                    this.data_hub.error_function('No reselect_checkbox found in subscriptionBox');
                }
        
            } else {
                const checkbox = subscriptionBox.querySelector('.checkbox');
                if (checkbox) {

                    // Toggle the checkbox state
                    checkbox.checked = !checkbox.checked;
                    this.update_user_data_iterable_subscription_arrays(checkbox);
                    subscriptions_preferences_form.update_subscription_box_classes(subscriptionBox, checkbox.checked);
                    this.update_checkbox_label(checkbox);
        
                } else {
                    console.error('No checkbox found in subscriptionBox');
                    this.data_hub.error_function('No checkbox found in subscriptionBox');
                }
            }
        }
    }

    manually_change_checked_from_scratch(reselect_checkbox, change_to_checked) {

        const event = new Event('change');
        reselect_checkbox.dispatchEvent(event);

        requestAnimationFrame(() => {
            reselect_checkbox.checked = change_to_checked;
        });

    }
    

    update_checkbox_label(checkbox) {

        const message_type_id = checkbox.dataset.referencesMessageTypeId;
        if (message_type_id) {

            const label = checkbox.closest('.subscription-box').querySelector(`[data-checkbox-label-for-message-type-id="${message_type_id}"]`);
            if (label) {
                label.textContent = checkbox.checked ? 'Subscribed' : 'Unsubscribed';
                if (checkbox.checked) {
                    label.classList.remove('text-red-600');
                    label.classList.add('text-green-600');
                } else {
                    label.classList.add('text-red-600');
                    label.classList.remove('text-green-600');
                }
            } else {
                console.error('No label found for message type id', message_type_id);
                this.data_hub.error_function('No label found for message type id');
            }
        }
    }

    append_email_to_form(form_container, data_hub) {
        if (!data_hub.user_data) {
            console.error('ERROR: append_email_to_form: No user data found');
            data_hub.error_function('No user data found');
            return;
        }

        if (!data_hub.user_data.email) {
            console.error('ERROR: append_email_to_form: No email found');
            data_hub.error_function('No email found');
            return;
        }

        if (!form_container) {
            console.error('ERROR: append_email_to_form: No form container found');
            data_hub.error_function('No form container found');
            return;
        }

        const form = form_container.querySelector('form');
        const email_input = document.createElement('input');
        email_input.type = 'hidden';
        email_input.name = 'email';
        email_input.value = data_hub.user_data.email;
        if (form) {
            form.prepend(email_input);
        } else {
            form_container.prepend(email_input);
        }
    }

    show_fewer_emails_success_popover(form_container, response) {
        console.log('show_fewer_emails_success_popover', response);
        // show thumbs up emoji
        const fewer_emails_popover = document.querySelectorAll('.fewer-emails-success');
        if (fewer_emails_popover) {
            fewer_emails_popover.forEach(element => {
                element.classList.remove('hidden');
            });
        }
    }

    open_email_preview_modal(previewUrl) {
        const modal = document.getElementById('email-preview-modal');
        const iframe = document.getElementById('email-preview-iframe');
        iframe.src = previewUrl;
        modal.classList.remove('hidden');
    }

    close_email_preview_modal(event=null) {

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const modal = document.getElementById('email-preview-modal');
        const iframe = document.getElementById('email-preview-iframe');
        iframe.src = '';
        modal.classList.add('hidden');
    }

    change_page_render_to_unsubscribe(form_container, data_hub_instance) {
        //(form_container, data_hub_action_object_to_send_to_api, this.data_hub)
        const if_no_email_find_first_with_value = (form_container, data_hub_action_object_to_send_to_api, data_hub_instance) => {

            if (!data_hub_instance) {
                console.error('ERROR: if_no_email_find_first_with_value: No data hub instance found');
                data_hub_instance.error_function('No data hub instance found');
                return;
            }

            if (!data_hub_instance.user_data.email) {
                if (form_container) {
                    const email_input = form_container.querySelector('input[name="email"]');
                    if (email_input && email_input.value) {
                        data_hub_instance.user_data.email = email_input.value;
                    } else {
                        const email_inputs = document.querySelectorAll('input[name="email"]');
                        let first_email_found = null;
                        if (email_inputs.length > 0) {
                            email_inputs.forEach(input => {
                                if (input.value && input.value.length > 0 && !first_email_found) {
                                    first_email_found = input.value;
                                }
                            });
                        }

                        if (first_email_found) {
                            data_hub_instance.user_data.email = first_email_found;
                        } else {
                            console.error('ERROR: if_no_email_find_first_with_value: No email found should have passed in url param or have jwt');
                            //data_hub_instance.error_function('No email found');
                        }
                    }
                }
            }
        }

        if (this.is_unsubscribe_page) {
            if (!data_hub_instance) {
                console.error('no data hub in subscriptions form cant return error_function');
                return;
            }

            if (!data_hub_instance.user_data) {
                if (data_hub_instance.error_function) {
                    return data_hub_instance.error_function('No user data object found','Please include your email address or phone number.');
                } else{
                    console.error('ERROR: change_page_render_to_unsubscribe: No user data found');
                    return;
                }
            }

            // Leave out since wont always have a jwt or passed in ?email=
            // if (!at_least_one_required_identifier_fields.some(field => data_hub_instance.user_data[field])) {
            //     if (data_hub_instance.error_function) {
            //         return data_hub_instance.error_function('No user data required fields found','Please include your email address or phone number.');
            //     } else{
            //         console.error('ERROR: change_page_render_to_unsubscribe: No user data found');
            //         return;
            //     }
            // }

            form_container.classList.add('is-unsubscribe');
            form_container.querySelectorAll('.hide_unsubscribe_page').forEach(element => {
                element.classList.add('hidden');
            });
            form_container.querySelectorAll('.show_unsubscribe_page').forEach(element => {
                element.classList.remove('hidden');
            });

            if (!event_sub_object) {
                event_sub_object = {
                    fewer_emails: true
                };
            } else {
                event_sub_object.fewer_emails = true;
            }

            //change event_name in event_sub_object to fewer_emails_event
            event_sub_object.event_name = 'fewer_emails_event';

            const fewer_emails_form = new data_hub_form({
                data_hub_instance: data_hub_instance,
            });

            fewer_emails_form.init({
                form_container_selector: '#receive_fewer_emails_button_contain',
                submit_button_selector: '.fewer_emails_button',
                pre_submit_function: if_no_email_find_first_with_value,
                return_function: this.show_fewer_emails_success_popover,
                metadata: {
                    "form_id": "240710-subscriptions-beta",
                },
                returns: { "user_data": "{{user_data}}" },
                events: [
                    {
                        "verb": "i_api",
                        "data": {
                            "api_method": "POST",
                            "api_endpoint": "events/track",
                            "i_api_json": {
                                "email": "{{user_data.email}}",
                                "eventName": "fewer_emails_event",
                                "dataFields": event_sub_object,
                                "createNewFields": true,
                                "campaignId": data_hub_instance.iterable_campaign_id || null,
                                "templateId": data_hub_instance.iterable_template_id || null
                            }
                        }
                    },
                    {
                        "verb": "i_api",
                        "data": {
                            "api_method": "POST",
                            "api_endpoint": "lists/subscribe",
                            "i_api_json": {
                                "listId": 4237390,
                                "subscribers":[{
                                    "email": "{{user_data.email}}",
                                }]
                            }
                        }
                    },
                    {
                        "verb": "data_hub_events",
                        "data": {
                            "event_name": "fewer_emails_event",
                            "custom_data": event_sub_object
                        }
                    },
                    {
                        "verb": "i_api",
                        "data": {
                            "api_method": "POST",
                            "api_endpoint": "users/update",
                            "i_api_json": {
                                "email": "{{user_data.email}}",
                                "dataFields": {
                                    "fewer_emails": true
                                }
                            }
                        }
                    }
                ]
            });

        }
    }

    position_arrow_pointer(form_container) {
        const arrow_pointer_selected = document.querySelector('.arrow-pointer');
        let target_element;

        if (is_unsubscribe_page) {
            // Unsubscribe page logic
            target_element = form_container.querySelector('.subscription-box .checkbox:checked');
            if (!target_element) {
                // If no subscriptions are checked, default to the first message type
                target_element = form_container.querySelector('.subscription-box');
            }
            arrow_pointer_selected.querySelector('.first-message-type-to-unsubscribe').classList.remove('hidden');
            arrow_pointer_selected.querySelector('.all-message-type-unsubscribed').classList.add('hidden');
        } else {
            // Subscribe page logic
            target_element = form_container.querySelector('.subscription-box .checkbox:not(:checked)');
            if (!target_element) {
                // If all are checked or new user, default to the first message type
                target_element = form_container.querySelector('.subscription-box');
                // Show all-message-type-subscribed
                arrow_pointer_selected.querySelector('.first-message-type-to-subscribe').classList.add('hidden');
                arrow_pointer_selected.querySelector('.all-message-type-subscribed').classList.remove('hidden');
            } else {
                arrow_pointer_selected.querySelector('.first-message-type-to-subscribe').classList.remove('hidden');
                arrow_pointer_selected.querySelector('.all-message-type-subscribed').classList.add('hidden');
            }
            
        }

        if (target_element) {
            const targetOffset = target_element.getBoundingClientRect().top + window.scrollY;
            const arrowHeight = arrow_pointer_selected.offsetHeight;
            let original_height_offset = targetOffset - arrowHeight / 2;
            if (is_unsubscribe_page) {
                original_height_offset = original_height_offset + 50; // Add 50px to offset for the header
            } else {
                original_height_offset = original_height_offset + 30;
            }
            arrow_pointer_selected.style.top = `${original_height_offset}px`;
        }

        arrow_pointer_selected.classList.remove('hidden');
        arrow_pointer_selected.classList.add('animate-fade-down');
    }


}

window.subscriptions_preferences_form = subscriptions_preferences_form; 