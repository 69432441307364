// data_hub_forms.js
import { 
    parse_boolean_falsy_types,
    remove_empty_values_from_object
} from '../helpers';
import { 
    white_list_incoming_person_fields,
    white_list_outgoing_person_fields,
    allowed_iterable_h_hash_key_names, 
    allow_boolean_only,
    form_input_types_string,
    at_least_one_required_identifier_fields
} from '../helpers/static_vars';

import {
    add_form_input_loading_state,
    remove_form_input_loading_state,

    add_form_loading_overlay,
    remove_form_loading_overlay,

    add_submit_button_loading_state,
    remove_submit_button_loading_state,


    prefill_form
} from '../forms/shared_form_ux_functions';

import defaultsDeep from 'lodash.defaultsdeep';

// INDIVIDUAL FORMS
export class data_hub_form {
    constructor(config = {}, callback_function_after_init = () => {}) {
        // CONFIG
        this.data_hub = config.data_hub_instance || window.data_hub;
        this.prefill_timeout = config.prefill_timeout || this.data_hub.prefill_timeout || 1300;
        this.post_person_timeout = config.post_person_timeout || 10000;
        this.prefill_input_selector = config.prefill_input_selector || this.data_hub.prefill_input_selector || '[data-allow-data_hub-prefill="true"]';

        // Shared or global data_hub functions
        this.pre_render_function = config.pre_render_function || (() => {});
        this.pre_submit_function = config.pre_submit_function || (() => {});
        this.error_function = config.error_function || this.data_hub.error_function || ((internal_error_message, external_error_message) => { console.error(internal_error_message, external_error_message) });
        this.return_function = config.return_function || ((results) => { console.log(results) });
        this.unload_function = config.unload_function || this.data_hub.unload_function || (() => { console.log('debug called data_hub_form top default unload') });

        if (!this.data_hub) {
            console.error('No data_hub_instance found');
            return this.error_function('No data_hub_instance found');
        }

        this.init_data(callback_function_after_init);
    }

    async init_data(callback_function_after_init) {
        if (typeof callback_function_after_init === 'function') {
            callback_function_after_init();
        }
    }

    init(settings = {}) {

        // Select the standard form elements to attach click events
        this.form_container_selector = settings.form_container_selector || '.data_hub_container';
        this.form_containers = settings.form_container 
            ? (settings.form_container instanceof HTMLElement ? [settings.form_container] : settings.form_container) 
            : document.querySelectorAll(this.form_container_selector);
    
        this.submit_button_selector = settings.submit_button_selector || '.data_hub_submit_form';
        this.submit_buttons = settings.submit_button 
            ? (settings.submit_button instanceof HTMLElement ? [settings.submit_button] : settings.submit_button)
            : null;
        
        // I think leave for now? because if we pass the shadow dom element in as form_container it should be able to sub select...
        this.reset_button_selector = settings.reset_button_selector || '.data_hub_reset_form';
        this.hide_on_submit_selector = settings.hide_on_submit_selector || '.hide-on-return-function';
        this.metadata_form_field_selector = settings.metadata_form_field_selector || '[data-metadata-form-field="true"]';
    
        // Form data
        this.events = settings.events || [];
        this.returns = settings.returns || {};
        this.metadata = settings.metadata || {};
        this.custom_data = settings.custom_data || {};
    
        // Settings
        this.merge_page_metadata = settings.merge_page_metadata || true;
        this.get_form_field_metadata = settings.get_form_field_metadata || true;
        this.return_submit_function_immediately = settings.return_submit_function_immediately || false;
    
        // Functions
        this.pre_render_function = settings.pre_render_function || this.pre_render_function || (() => {});
        this.pre_submit_function = settings.pre_submit_function || this.pre_submit_function || (() => {});
        this.error_function = settings.error_function || this.data_hub.error_function || (() => {});
        this.return_function = settings.return_function || this.return_function || (() => {});
        this.unload_function = settings.unload_function || ((event, error_function, is_data_hub_form_submission_in_progress) => {});
    
        // Pre Fills
        this.has_at_least_one_prefill = false;
        this.allow_prefill = settings.allow_prefill !== undefined ? settings.allow_prefill : true;
    
        // Check for existing form containers
        if (!this.form_containers || this.form_containers.length === 0) {
            console.error('No form containers found');
            return this.error_function('No form containers found');
        }

        // Ensure form_containers is always an array
        if (!Array.isArray(this.form_containers)) {
            this.form_containers = Array.from(this.form_containers);
        }
    
        // Bind submit, reset clicks for submission to each form based on passed-in selectors
        this.form_containers.forEach(form_container => {
            this.pre_render_function(form_container, this);
    
            let bound_at_least_one_submit = false;
    
            const forms = form_container.querySelectorAll('form');
            forms.forEach(form => {
                // Check if the form has a bound submit button or input
                if (form.querySelector('input[type="submit"], button[type="submit"], input[type="image"]')) {
                    bound_at_least_one_submit = true;
                }
    
                form.addEventListener('submit', (event) => {
                    event.preventDefault();
                    this.submit_form_data(event, form_container);
                });
            });
    
            // Handling potential double-binding issue
            const submit_buttons = this.submit_buttons || form_container.querySelectorAll(this.submit_button_selector);
            submit_buttons.forEach(submit_button => {
                if (submit_button && !submit_button.dataset.bound) {
                    submit_button.dataset.bound = 'true';  // Flag to indicate binding
                    bound_at_least_one_submit = true;
                    submit_button.onclick = (event) => {
                        event.preventDefault();
                        this.submit_form_data(event, form_container);
                    };
                }
            });
    
            // If no bound submits, return error
            if (!bound_at_least_one_submit) {
                console.error('No submit buttons found for a form in the container');
                return this.error_function('No submit buttons found');
            }
    
            // Prefill form fields if applicable
            if (this.allow_prefill || this.data_hub.prefill_all_forms) {
                prefill_form(form_container, this.data_hub?.user_data || {}, this.prefill_input_selector, this.reset_button_selector);
            }
        });
    
        // Set new unload bind. check if the unload function is already set
        // if (this.unload_function) {
        //     // check existing window event listeners for beforeunload
        //     if (!window.beforeUnloadSet) {
        //         window.beforeUnloadSet = new Set();
        //     }
    
        //     if (!window.beforeUnloadSet.has(this.unload_function)) {
        //         window.addEventListener('beforeunload', (event) => {
        //             this.unload_function(event, this.error_function, window.is_data_hub_form_submission_in_progress);
        //         });
        //         window.beforeUnloadSet.add(this.unload_function);
        //     }
        // }
    }
    

    get_metadata_form_fields(form_container) {
        if (!this.metadata_form_field_selector) {
            return {};
        }
        if (!form_container) {
            return this.error_function('No form container found');
        }
        const metadata_fields = form_container.querySelectorAll(this.metadata_form_field_selector);
        let metadata = {};
        if (metadata_fields) {
            metadata_fields.forEach(field => {
                const key = field.id || field.name;
                if (key) {
                    metadata[key] = field.value;
                }
            });
        }
        return metadata;
    }

    verify_action_array(action_array) {
        if (!Array.isArray(action_array)) {
            return this.error_function('Action array is not an array');
        }
        // must contain objects
        for (const action of action_array) {
            if (typeof action !== 'object') {
                return this.error_function('Action array contains non-object');
            }
            // Objects must have only 2 keys, verb and data
            if (Object.keys(action).length !== 2) {
                return this.error_function('Action object does not have 2 keys ' + Object.keys(action));
            }
            if (!action.verb || !action.data) {
                return this.error_function('Action object does not have verb and data keys ' + Object.keys(action));
            }
            // Verb keys can only be i_api or data_hub_events
            if (!['i_api', 'data_hub_events'].includes(action.verb)) {
                return this.error_function('Action object verb key is not i_api or data_hub_events ' + action.verb);
            }
        }
        return true;
    }

    return_error_clear_form_loading_state(form_container, internal_error_message = 'Error submitting form data', external_error_message = 'Error submitting your request. Refresh page to try again or contact support.') {
        remove_submit_button_loading_state(form_container, this.error_function);
        remove_form_loading_overlay(form_container);
        remove_form_input_loading_state(form_container);

        return this.error_function(internal_error_message, external_error_message);
    }

    async submit_form_data(event, form_container) {
        // Start timer to log
        const start_time = new Date().getTime();

        try {
            event.preventDefault();

            if (!form_container) {
                console.error('No form container found');
                return this.error_function('No form container found');
            }

            add_submit_button_loading_state(form_container, this.error_function);
            add_form_input_loading_state(form_container);
            add_form_loading_overlay(form_container);

            let data_hub_action_object_to_send_to_api = {
                events: this.events,
                returns: this.returns,
                custom_data: this.custom_data,
                user_data: this.data_hub.user_data,
                metadata: this.metadata,
            };

            for (const [key, value] of this.data_hub.url_params) {
                if (allowed_iterable_h_hash_key_names.includes(key)) {
                    data_hub_action_object_to_send_to_api.user_data[key] = value;
                }
            }

            const form_elements = form_container.querySelectorAll(form_input_types_string);
            if (!form_elements) {
                return this.return_error_clear_form_loading_state(form_container, 'No form elements found');
            }

            Array.from(form_elements).forEach(element => {
                const key = element.id || element.name;

                if (key && white_list_incoming_person_fields.includes(key)) {
                    if (['checkbox', 'radio'].includes(element.type)) {
                        this.data_hub.user_data[key] = parse_boolean_falsy_types(element.checked);
                    } else if (['text', 'email', 'tel', 'password', 'url', 'hidden', 'number', 'date', 'datetime-local', 'month', 'time', 'week'].includes(element.type)) {
                        this.data_hub.user_data[key] = element.value;
                    } else if (element.tagName === 'SELECT' || element.tagName === 'TEXTAREA') {
                        this.data_hub.user_data[key] = element.value;
                    } else {
                        console.error('Unsupported input type:', element.type, element);
                    }
                }
            });

            for (const field of allow_boolean_only) {
                if (this.data_hub.user_data[field]) {
                    this.data_hub.user_data[field] = parse_boolean_falsy_types(this.data_hub.user_data[field]);
                }
            }

            if (this.data_hub.iterable_campaign_id) {
                data_hub_action_object_to_send_to_api.metadata.iterable_campaign_id = this.data_hub.iterable_campaign_id;
            }
            if (this.data_hub.iterable_template_id) {
                data_hub_action_object_to_send_to_api.metadata.iterable_template_id = this.data_hub.iterable_template_id;
            }

            if (this.events.length === 0 && Object.keys(this.data_hub.user_data).length === 0) {
                return this.return_error_clear_form_loading_state(form_container, 'No events or user data to submit');
            }

            if (Object.keys(data_hub_action_object_to_send_to_api.user_data).length === 0) {
                return this.return_error_clear_form_loading_state(form_container, 'No user data to submit');
            }

            let has_at_least_one_identifier = false;
            for (const field of at_least_one_required_identifier_fields) {
                if (this.data_hub.user_data[field]) {
                    has_at_least_one_identifier = true;
                    break;
                }
            }
            if (!has_at_least_one_identifier) {
                Array.from(form_elements).forEach(element => {
                    if ((element.id && at_least_one_required_identifier_fields.includes(element.id)) || (element.name && at_least_one_required_identifier_fields.includes(element.name))) {
                        let existing_class_list_values = element.classList.value;
                        existing_class_list_values.split(' ').forEach(class_name => {
                            if (class_name.startsWith('border')) {
                                element.classList.remove(class_name);
                            }
                        });

                        element.classList.add('border-red-500', 'border');
                    }
                });

                return this.return_error_clear_form_loading_state(form_container, 'Missing at least one required user identifier', 'Please provide an email or phone number');
            }

            if (data_hub_action_object_to_send_to_api.events && Array.isArray(data_hub_action_object_to_send_to_api.events) 
                && data_hub_action_object_to_send_to_api.events.length > 0) {
                let is_valid_action_array = this.verify_action_array(data_hub_action_object_to_send_to_api.events);
                if (is_valid_action_array !== true) {
                    return this.return_error_clear_form_loading_state(form_container, 'Invalid action array');
                }
            }

            for (const key in data_hub_action_object_to_send_to_api.user_data) {
                if (!white_list_outgoing_person_fields.includes(key) && !allowed_iterable_h_hash_key_names.includes(key)) {
                    delete data_hub_action_object_to_send_to_api.user_data[key];
                }
            }

            if (this.get_form_field_metadata) {
                data_hub_action_object_to_send_to_api.metadata = defaultsDeep(
                    data_hub_action_object_to_send_to_api.metadata,
                    this.get_metadata_form_fields(form_container)
                );
            }

            if (this.merge_page_metadata) {
                if (this.data_hub.page_metadata && Object.keys(this.data_hub.page_metadata).length > 0) {
                    data_hub_action_object_to_send_to_api.metadata = defaultsDeep(
                        data_hub_action_object_to_send_to_api.metadata,
                        this.data_hub.page_metadata
                    );
                }
            }

            data_hub_action_object_to_send_to_api.user_data = remove_empty_values_from_object(data_hub_action_object_to_send_to_api.user_data);

            this.pre_submit_function(form_container, data_hub_action_object_to_send_to_api, this.data_hub);

            let response_data = {};

            const user_data_api_url = `${this.data_hub.forms_domain}/api/post/user_data`;

            let already_called_return_function = false;
            if (this.return_submit_function_immediately) {
                this.hide_on_submit(this.hide_on_submit_selector);
                this.return_function(form_container, data_hub_action_object_to_send_to_api, this.data_hub);
                already_called_return_function = true;
            }

            try {
                window.is_data_hub_form_submission_in_progress = true;

                const response = await fetch(user_data_api_url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data_hub_action_object_to_send_to_api),
                    timeout: this.post_person_timeout || 5000
                });

                window.is_data_hub_form_submission_in_progress = false;

                if (!response.ok) {
                    console.error('Error submitting form data:', response.status || null, response.statusText || null);
                    return this.return_error_clear_form_loading_state(form_container, 'Error submitting form data');
                }

                response_data = await response.json();

            } catch (error) {
                window.is_data_hub_form_submission_in_progress = false;
                console.error(error);
                return this.return_error_clear_form_loading_state(form_container, 'Error submitting form data');
            } finally {
                remove_form_input_loading_state(form_container);
                remove_form_loading_overlay(form_container);
                remove_submit_button_loading_state(form_container, this.error_function);

                const end_time = new Date().getTime();
                console.log('Time to submit form:', end_time - start_time);

                if (!already_called_return_function) {
                    console.log('not called already_called_return_function response_data', response_data);
                    this.hide_on_submit(this.hide_on_submit_selector);
                    return this.return_function(form_container, response_data, this.data_hub);
                } else {
                    console.log('called already_called_return_function response_data', response_data);
                }
            }
        } catch (error) {
            window.is_data_hub_form_submission_in_progress = false;
            console.error('error', error, 'time to error:', new Date().getTime() - start_time);
            return this.error_function(error);
        }
    }

    // TODO use this type of selector to update the other one. add in the # single id or .classes
    hide_on_submit(selector = this.hide_on_submit_selector) {
        console.log('debug hide  on submit selector', selector);
        let hide_on_submit_elements = null;
    
        if (typeof selector === 'string') {
            hide_on_submit_elements = document.querySelectorAll(selector);
        } else if (selector instanceof HTMLElement) {
            hide_on_submit_elements = [selector];
        } else if (selector instanceof NodeList || Array.isArray(selector)) {
            hide_on_submit_elements = selector;
        }
    
        if (hide_on_submit_elements && hide_on_submit_elements.length > 0) {
            hide_on_submit_elements.forEach(element => {
                element.classList.add('hidden');
            });
        }
    }
    
}




window.data_hub_form = data_hub_form;