// data_hub.js
import { 
    parse_int_or_return_null,
    return_valid_forms_domain,
    extract_array_values_from_url_parameter,
    detect_parse_url_param_types,
    generate_gradient_stops,
    find_closest_shade,
    to_kebab_case
} from '../helpers';
import { 
    white_list_incoming_person_fields, 
    array_like_url_params,
    allowed_iterable_h_hash_key_names
} from '../helpers/static_vars';

import {
    show_error_popover,

    add_form_input_loading_state,
    remove_form_input_loading_state,

    add_form_loading_overlay,
    remove_form_loading_overlay,

    prefill_form

} from '../forms/shared_form_ux_functions';

import defaultsDeep from 'lodash.defaultsdeep';

const DEFAULT_THEME_COLORS = {
    primary_brand_color: {
        600: "#0284c7" // 600 is primary brand color, Daily Kos orange is #ea7806. Tailwind sky is #0284c7.
    },
    contrast_brand_color: {
        600: "#059669" // Tailwind emerald
    },
    highlight_brand_color: {
        600: "#fbbf24" // Tailwind amber 400
    },
    grey_brand_color: {
        600: "#475569" // Tailwind slate
    }
};

// Global sets
if (!window.beforeUnloadSet) {
    window.beforeUnloadSet = new Set();
}
if (!window.prefilled_forms) {
    window.prefilled_forms = new Set(); // Track prefilled forms so dont pre-fill a single data_hub_forms instance twice
}

// TODO work on this passing in vars doesnt work idk..
// const default_unload_function = (event, error_function = null, is_submission_in_progress = null) => {
//     event.preventDefault();
//     //event.returnValue = '';
//     console.log('debug: could call shared amplitude conversion here is_submission_in_progress', is_submission_in_progress, 'error_function', error_function);

//     if (window.is_data_hub_form_submission_in_progress && window.is_data_hub_form_submission_in_progress === true) {
//         const errorFunc = error_function || window.data_hub_error_function || ((msg) => console.error(msg));
//         //event.returnValue = 'A form submission is in progress. Are you sure you want to leave?';
//         errorFunc('A form submission is in progress. Are you sure you want to leave?');
//     }
// };

// GLOBALS
export class data_hub {
    constructor(config = {}, callback_function_after_init = () => {}) {

        this.forms_domain = return_valid_forms_domain(config.forms_domain || "https://action-two-dot-daily-kos-beta.uc.r.appspot.com");
        this.url_params = new URLSearchParams(window.location.search);

        // Global shared user_data object
        if (!config.user_data || typeof config.user_data !== 'object') {
            config.user_data = {};
        }
        this.user_data = config.user_data || {};

        // Create page_metadata and load iterable campaign & template IDS
        this.get_metadata_and_iterable_ids(config);

        /// Prefill settings
        this.prefill_input_selector = '[data-allow-data_hub-prefill="true"]';
        this.reset_button_selector = '.data_hub_reset_form';
        this.prefill_all_forms = config.prefill_all_forms || true;
        this.block_page_on_user_data_load = config.block_page_on_user_data_load || false;
        this.prefill_timeout = config.prefill_timeout || 1300;
        this.get_iterable_user_data_via_hmac_key = config.get_iterable_user_data_via_hmac_key || false;

        this.callback_function_after_init = callback_function_after_init;

        const default_error_function = (internal_error_message, external_error_message) => { console.error(internal_error_message, external_error_message) };
        this.error_function = config.error_function || show_error_popover || default_error_function;

       // this.unload_function = config.unload_function || default_unload_function;

        // Make error_function globally accessible
        window.data_hub_error_function = this.error_function;
        window.is_data_hub_form_submission_in_progress = false;

        // Inject Tailwind-like CSS styles if theme is provided
        if (!config.theme) { config.theme = {} }
        const theme = defaultsDeep({}, config.theme, DEFAULT_THEME_COLORS);

        // Target for style injection is the shadow root of the custom element
        if (config.target) {
            this.inject_styles(theme, config.target);
        } else {
            this.inject_styles(theme, document.head); // Fallback to document head
        }

        // Start user data load and global form prefill
        this.start_data_hub_init();

    }

    async start_data_hub_init() {
        let has_hit_prefill_timeout = false;
    
        if (this.prefill_all_forms) {
            add_form_input_loading_state();
            add_form_loading_overlay();
        }
    
        if (this.block_page_on_user_data_load) {
            await this.load_user_data_from_jwt_url_params(this.get_iterable_user_data_via_hmac_key)
                .finally(() => {
                    this.start_prefill_forms(has_hit_prefill_timeout);
                    if (this.callback_function_after_init) {
                        this.callback_function_after_init();
                    }
                });
        } else {
            this.load_user_data_from_jwt_url_params(this.get_iterable_user_data_via_hmac_key)
                .then(() => {
                    this.start_prefill_forms(has_hit_prefill_timeout)
                    if (this.callback_function_after_init) {
                        this.callback_function_after_init();
                    }
                });
        }
    
        if (this.prefill_timeout) {
            setTimeout(() => {
                has_hit_prefill_timeout = true;
                remove_form_input_loading_state();
                remove_form_loading_overlay();
            }, this.prefill_timeout);
        }
    
       
    
        // Set unload function, use passed in functions or default
        // if (!window.beforeUnloadSet.has(this.unload_function)) {
        //     window.addEventListener('beforeunload', (event) => {
        //         this.unload_function(event, this.error_function, window.is_data_hub_form_submission_in_progress);
        //     });
        //     window.beforeUnloadSet.add(this.unload_function);
        // }
    }

    start_prefill_forms(has_hit_prefill_timeout) {
        if (this.prefill_all_forms && !has_hit_prefill_timeout) {
            prefill_form(document, this.user_data, this.input_selector, this.reset_button_selector);
            remove_form_input_loading_state();
            remove_form_loading_overlay();
        }
    }

    // FUNCTIONS AND HELPERS
    //----------------------------------------------
    // TAILWIND LIKE CSS INJECTION
    inject_styles(theme, target) {

        // if no target reject
        if (!target) {
            console.error('Error inject_styles target is null:', target);
            return
        }
        //if no theme
        if (!theme) {
            console.error('Error inject_styles theme is null:', theme);
            return
        }

        const style = document.createElement('style');
        style.type = 'text/css';

        let css = '';
        Object.keys(theme).forEach(color_key => {
            const color = theme[color_key];
            let colors = color;
            const color_key_kebab = to_kebab_case(color_key);

            if (typeof color === 'string') {
                colors = generate_gradient_stops(color);
            } else if (typeof color === 'object' && Object.keys(color).length < 9) {
                const closest_shade = find_closest_shade(color, 600);
                colors = { ...generate_gradient_stops(color[closest_shade]), ...color };
            } else if (typeof color !== 'object' || Object.keys(color).length != 9) {
                console.error('Error inject_styles color is not a string or object with 9 keys:', color);
                return;
            }

            Object.keys(colors).forEach(shade_key => {
                if (!shade_key.toString().match(/^(100|200|300|400|500|600|700|800|900)$/)) {
                    console.error('Error inject_styles shade_key is not a tailwind number:', shade_key);
                    return;
                }

                const class_name = `${color_key_kebab}-${shade_key}`;
                css += `
                    .text-${class_name} { color: ${colors[shade_key]}; }
                    .bg-${class_name} { background-color: ${colors[shade_key]}; }
                    .border-${class_name} { border-color: ${colors[shade_key]}; }
                    .ring-${class_name} { ring-color: ${colors[shade_key]}; }
                    .shadow-${class_name} { box-shadow: 0 1px 2px 0 ${colors[shade_key]}; }
                    .placeholder-${class_name}::placeholder { color: ${colors[shade_key]}; }
                    .divide-${class_name} > * + * { border-color: ${colors[shade_key]}; }
                    .decoration-${class_name} { text-decoration-color: ${colors[shade_key]}; }
                    .caret-${class_name} { caret-color: ${colors[shade_key]}; }
                    .accent-${class_name} { accent-color: ${colors[shade_key]}; }
                    .outline-${class_name} { outline-color: ${colors[shade_key]}; }
                    .from-${class_name} { --tw-gradient-from: ${colors[shade_key]} var(--tw-gradient-from-position); }
                    .ring-offset-${class_name} { --tw-ring-offset-color: ${colors[shade_key]}; }
                    .shadow-${class_name} { --tw-shadow-color: ${colors[shade_key]}; }
                    .fill-${class_name} { fill: ${colors[shade_key]}; }
                    .stroke-${class_name} { stroke: ${colors[shade_key]}; }

                    .hover\\:text-${class_name}:hover { color: ${colors[shade_key]}; }
                    .hover\\:bg-${class_name}:hover { background-color: ${colors[shade_key]}; }
                    .hover\\:border-${class_name}:hover { border-color: ${colors[shade_key]}; }
                    .hover\\:ring-${class_name}:hover { ring-color: ${colors[shade_key]}; }
                    .hover\\:shadow-${class_name}:hover { box-shadow: 0 1px 2px 0 ${colors[shade_key]}; }
                    .hover\\:placeholder-${class_name}:hover::placeholder { color: ${colors[shade_key]}; }
                    .hover\\:divide-${class_name}:hover > * + * { border-color: ${colors[shade_key]}; }

                    .focus\\:text-${class_name}:focus { color: ${colors[shade_key]}; }
                    .focus\\:bg-${class_name}:focus { background-color: ${colors[shade_key]}; }
                    .focus\\:border-${class_name}:focus { border-color: ${colors[shade_key]}; }
                    .focus\\:ring-${class_name}:focus { ring-color: ${colors[shade_key]}; }
                    .focus\\:shadow-${class_name}:focus { box-shadow: 0 1px 2px 0 ${colors[shade_key]}; }
                    .focus\\:placeholder-${class_name}:focus::placeholder { color: ${colors[shade_key]}; }
                    .focus\\:divide-${class_name}:focus > * + * { border-color: ${colors[shade_key]}; }

                    .active\\:text-${class_name}:active { color: ${colors[shade_key]}; }
                    .active\\:bg-${class_name}:active { background-color: ${colors[shade_key]}; }
                    .active\\:border-${class_name}:active { border-color: ${colors[shade_key]}; }
                    .active\\:ring-${class_name}:active { ring-color: ${colors[shade_key]}; }
                    .active\\:shadow-${class_name}:active { box-shadow: 0 1px 2px 0 ${colors[shade_key]}; }
                    .active\\:placeholder-${class_name}:active::placeholder { color: ${colors[shade_key]}; }
                    .active\\:divide-${class_name}:active > * + * { border-color: ${colors[shade_key]}; }
                `;
            });

            if (colors['600']) {
                css += `
                    .text-${color_key_kebab} { color: ${colors['600']}; }
                    .bg-${color_key_kebab} { background-color: ${colors['600']}; }
                    .border-${color_key_kebab} { border-color: ${colors['600']}; }
                    .ring-${color_key_kebab} { ring-color: ${colors['600']}; }
                    .shadow-${color_key_kebab} { box-shadow: 0 1px 2px 0 ${colors['600']}; }
                    .placeholder-${color_key_kebab}::placeholder { color: ${colors['600']}; }
                    .divide-${color_key_kebab} > * + * { border-color: ${colors['600']}; }
                    .decoration-${color_key_kebab} { text-decoration-color: ${colors['600']}; }
                    .caret-${color_key_kebab} { caret-color: ${colors['600']}; }
                    .accent-${color_key_kebab} { accent-color: ${colors['600']}; }
                    .outline-${color_key_kebab} { outline-color: ${colors['600']}; }
                    .from-${color_key_kebab} { --tw-gradient-from: ${colors['600']} var(--tw-gradient-from-position); }
                    .ring-offset-${color_key_kebab} { --tw-ring-offset-color: ${colors['600']}; }
                    .shadow-${color_key_kebab} { --tw-shadow-color: ${colors['600']}; }
                    .fill-${color_key_kebab} { fill: ${colors['600']}; }
                    .stroke-${color_key_kebab} { stroke: ${colors['600']}; }

                    .hover\\:text-${color_key_kebab}:hover { color: ${colors['600']}; }
                    .hover\\:bg-${color_key_kebab}:hover { background-color: ${colors['600']}; }
                    .hover\\:border-${color_key_kebab}:hover { border-color: ${colors['600']}; }
                    .hover\\:ring-${color_key_kebab}:hover { ring-color: ${colors['600']}; }
                    .hover\\:shadow-${color_key_kebab}:hover { box-shadow: 0 1px 2px 0 ${colors['600']}; }
                    .hover\\:placeholder-${color_key_kebab}:hover::placeholder { color: ${colors['600']}; }
                    .hover\\:divide-${color_key_kebab}:hover > * + * { border-color: ${colors['600']}; }

                    .focus\\:text-${color_key_kebab}:focus { color: ${colors['600']}; }
                    .focus\\:bg-${color_key_kebab}:focus { background-color: ${colors['600']}; }
                    .focus\\:border-${color_key_kebab}:focus { border-color: ${colors['600']}; }
                    .focus\\:ring-${color_key_kebab}:focus { ring-color: ${colors['600']}; }
                    .focus\\:shadow-${color_key_kebab}:focus { box-shadow: 0 1px 2px 0 ${colors['600']}; }
                    .focus\\:placeholder-${color_key_kebab}:focus::placeholder { color: ${colors['600']}; }
                    .focus\\:divide-${color_key_kebab}:focus > * + * { border-color: ${colors['600']}; }

                    .active\\:text-${color_key_kebab}:active { color: ${colors['600']}; }
                    .active\\:bg-${color_key_kebab}:active { background-color: ${colors['600']}; }
                    .active\\:border-${color_key_kebab}:active { border-color: ${colors['600']}; }
                    .active\\:ring-${color_key_kebab}:active { ring-color: ${colors['600']}; }
                    .active\\:shadow-${color_key_kebab}:active { box-shadow: 0 1px 2px 0 ${colors['600']}; }
                    .active\\:placeholder-${color_key_kebab}:active::placeholder { color: ${colors['600']}; }
                    .active\\:divide-${color_key_kebab}:active > * + * { border-color: ${colors['600']}; }
                `;
            } else {
                console.error('Error inject_styles no 600 color_key:', color_key, 'color_key_kebab', color_key_kebab, 'colors:', colors);
            }
        });

        style.innerHTML = css;

        target.appendChild(style);
    }
    
    get_metadata_url_params() {
        for (const [key, value] of this.url_params) {
            if (key.startsWith('metadata_b64')) {
                try {
                    let decoded_value = atob(value);
                    let parsed_value = JSON.parse(decoded_value);
                    // smart deep merge defaultsDeep function from _ lodash
                    /// TODO OPENAI i dont think this is working
                    this.page_metadata = defaultsDeep(this.page_metadata, parsed_value);
                    //this.page_metadata = { ...this.page_metadata, ...parsed_value };
                } catch (error) {
                    console.error('Error get_metadata_url_params decoding metadata_b64_ key:', error);
                }
            } else if (key.startsWith('metadata_')) {
                let new_key_name = key.replace('metadata_', '');
                this.page_metadata[new_key_name] = detect_parse_url_param_types(value);
            }
        }
    }

    get_metadata_and_iterable_ids(config) {
        if (!config.page_metadata || typeof config.page_metadata !== 'object') {
            config.page_metadata = {};
        }
        this.page_metadata = config.page_metadata || {};
        // Gets url params and merges with passed values. 
        this.get_metadata_url_params();

        // Pass iterable campaign IDs or look in url params
        this.iterable_campaign_id = null;
        if (config.iterable_campaign_id) {
            this.iterable_campaign_id = config.iterable_campaign_id;
        }

        this.iterable_template_id = null;
        if (config.iterable_template_id) {
            this.iterable_template_id = config.iterable_template_id;
        }

        this.iterable_campaign_id = config.iterable_campaign_id || null;
        this.iterable_template_id = config.iterable_template_id || null;
        // if either is null get them from url params
        if (!this.iterable_campaign_id || !this.iterable_template_id) {
            this.get_iterable_url_params();
        }
    }

    // Can pased a combined key or individual seperate keys. only updates if not already set from passed value
    get_iterable_url_params() {
        for (const [key, value] of this.url_params) {
            if (key.startsWith('iterable_')) {
                let found_iterable_campaign_template = this.parse_iterable_refcode(value);

                if (this.iterable_campaign_id === null) {
                    this.iterable_campaign_id = found_iterable_campaign_template.iterable_campaign_id;
                }
                if (this.iterable_template_id === null) {
                    this.iterable_template_id = found_iterable_campaign_template.iterable_template_id;
                }
            } else if (key === 'iterableCampaignId') {
                if (this.iterable_campaign_id === null) {
                    this.iterable_campaign_id = is_valid_iterable_refcode_parse_to_int(value);
                }
            } else if (key === 'iterableTemplateId') {
                if (this.iterable_template_id === null) {
                    this.iterable_template_id = is_valid_iterable_refcode_parse_to_int(value);
                }
            }
        }
    }

    is_valid_iterable_refcode_parse_to_int(refcode) {
        try {
            refcode = parse_int_or_return_null(refcode);
            if (refcode < 5000000) {
                console.error('not a valid iterable refcode too low of a number:', refcode);
                return null;
            }
            return refcode;
        } catch (error) {
            console.error('Error is_valid_iterable_refcode_parse_to_int:', error);
            return null;
        }
    }

    // Parse single iterable refcode key. If it starts with iterable_ and has _c_ or _t_ in it, parse it.
    parse_iterable_refcode(refcode) {

        if (refcode.startsWith('iterable_') && (refcode.includes('_c_') || (refcode.includes('_t')))) {
            refcode = refcode.replace('iterable_', '');
            let parts = refcode.split('_');

            if (parts.includes('c') || parts.includes('t')) {
                for (let i = 0; i < parts.length; i++) {
                    if (parts[i] === 'c' && i + 1 < parts.length) {
                        iterable_campaign_id = parts[i + 1];
                    } else if (parts[i] === 't' && i + 1 < parts.length) {
                        iterable_template_id = parts[i + 1];
                    }
                }
            }
        }

        return {
            iterable_campaign_id: is_valid_iterable_refcode_parse_to_int(iterable_campaign_id),
            iterable_template_id: is_valid_iterable_refcode_parse_to_int(iterable_template_id)
        };
    }


    async load_user_data_from_jwt_url_params(get_iterable_user_data_via_hmac_key = this.get_iterable_user_data_via_hmac_key || false) {
        try {
            await this.get_user_data_from_jwt(get_iterable_user_data_via_hmac_key);
            await this.get_user_url_params();
        } catch (error) {
            this.error_function('Error load_user_data_from_jwt_url_params:', error);
        }
    }

    async get_user_data_from_jwt(get_iterable_user_data_via_hmac_key = this.get_iterable_user_data_via_hmac_key || false) {
        try {

            // if get_iterable_user_data_via_hmac_key is true, loop through allowed_iterable_h_hash_key_names and get those values
            let these_user_hmac_key_values = {};
            let has_at_least_one_hmac_hash = false;
            if (get_iterable_user_data_via_hmac_key) {
                for (const key of allowed_iterable_h_hash_key_names) {
                    // append the original user_data key if it exists
                    let key_minus_h = key.replace('h_iterable_', '');
                    if (this.user_data[key_minus_h]) {
                        these_user_hmac_key_values[key_minus_h] = this.user_data[key];
                        has_at_least_one_hmac_hash = true;
                    } else if (this.url_params.has(key_minus_h)) {
                        these_user_hmac_key_values[key_minus_h] = this.url_params.get(key_minus_h);
                        has_at_least_one_hmac_hash = true;
                    }
                    // append the hashed hmac keys if they exist
                    if (this.url_params.has(key)) {
                        these_user_hmac_key_values[key] = this.url_params.get(key);
                    } else if (this.user_data[key]) {
                        these_user_hmac_key_values[key] = this.user_data[key];
                    }
                }
            }
                    
            let user_jwt_url = `${this.forms_domain}/api/get/user_jwt`;

            // if get_iterable_user_data_via_hmac_key is true, add these_user_hmac_key_values to the url
            if (has_at_least_one_hmac_hash === true && get_iterable_user_data_via_hmac_key && Object.keys(these_user_hmac_key_values).length > 0) {
                user_jwt_url += `?${new URLSearchParams(these_user_hmac_key_values).toString()}`;
            }

            const response = await fetch(user_jwt_url, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) return console.error('User jwt fetch down? ', response);

            let json = await response.json();
            if (json && typeof json === 'object') {
                Object.keys(json).forEach(key => {
                    if (!white_list_incoming_person_fields.includes(key)) {
                        delete json[key];
                    }
                });

                this.user_data = { ...this.user_data, ...json };
                return this.user_data;
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Error get_user_data_from_jwt fetching user data:', error);
                return;
            }
        }
    }

    async get_user_url_params() {
        if (!this.url_params) {
            return;
        }
        this.url_params.forEach((value, key) => {
            if (white_list_incoming_person_fields.includes(key)) {
                if (array_like_url_params.includes(key)) {
                    const parsed_value = extract_array_values_from_url_parameter(value);

                    if (parsed_value && Array.isArray(parsed_value)) {
                        let array_of_numbers = [];
                        for (const array_value of parsed_value) {
                            let number_or_null = parse_int_or_return_null(array_value);
                            if (number_or_null) {
                                array_of_numbers.push(number_or_null);
                            }
                        }

                        if (array_of_numbers.length === 0) {
                            console.error('Error get_user_url_params array_of_numbers is empty:', key, value, parsed_value);
                        }

                        this.user_data[key] = array_of_numbers;
                    }
                } else if (typeof value === 'string' && ((value.startsWith('[') && value.endsWith(']')) || (value.startsWith('{') && value.endsWith('}')))) {
                    try {
                        const parsed_value = JSON.parse(value);
                        if (Array.isArray(parsed_value)) {
                            this.user_data[key] = parsed_value;
                        }
                    } catch (error) {
                        console.error('Error get_user_url_params parsing array-like value:', error);
                    }
                } else if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
                    this.user_data[key] = value;
                } else {
                    console.error("Error get_user_url_params: value is not a string, number, or boolean ", value);
                }
            }
            //else is an allowed allowed_iterable_h_hash_key_names
            if (allowed_iterable_h_hash_key_names.includes(key)) {
                this.user_data[key] = value;
            }

        });

    }
}

window.data_hub = data_hub;