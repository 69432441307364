const {
    white_list_outgoing_person_fields,
    user_data_form_field_types,
    html_input_autocomplete_types_by_user_data_key_name,
    user_data_form_field_placeholders
} = require('../helpers/static_vars');

// Import Tailwind CSS as a string for Shadow DOM injection
const tailwindCSS = require('../styles/tailwind.css').default; // Correct import

class data_hub_signup_form extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' }); // Use Shadow DOM for encapsulation
    }

    connectedCallback() {
        if (!this.isInitialized) {
            this.render();
            this.isInitialized = true; // Flag to prevent double initialization
        }
    }

    inject_tailwind_css(container) {
        // Check if Tailwind CSS is already injected in the container
        if (!container.querySelector('style[data-tailwind]')) {
            const style = document.createElement('style');
            style.textContent = tailwindCSS; // Inject the CSS string into the specified container
            style.setAttribute('data-tailwind', ''); // Mark the style tag to avoid re-injection
            container.appendChild(style); // Append style to the specific container
        } else {
            console.log('Tailwind CSS already injected in container.'); // Debug: Already injected
        }
    }

    render() {
        const form_title = this.getAttribute('data-data-hub-form-title') || 'Sign up for our newsletter';
        const form_description = this.getAttribute('data-data-hub-form-description') || 'Stay updated with the latest news and updates.';
        const form_submit_button_text = this.getAttribute('data-data-hub-form-submit-button-text') || 'Sign up';
        const user_fields = this.getAttribute('data-data-hub-form-user-fields') || 'email';

        // Check for show_sms_optin attribute presence and set default to true
        const show_sms_optin = this.hasAttribute('data-data-hub-form-show-sms-optin')
            ? this.getAttribute('data-data-hub-form-show-sms-optin') === 'true'
            : true; // Default to true if not present

        // Check for allow_prefill attribute presence and set default to true
        const allow_prefill = this.hasAttribute('data-data-hub-form-allow-prefill')
            ? this.getAttribute('data-data-hub-form-allow-prefill') === 'true'
            : true; // Default to true if not present

        // Check for isolate_data_hub_instances attribute presence and set default to false
        const isolate_data_hub_instances = this.hasAttribute('data-data-hub-isolate-instances')
            ? this.getAttribute('data-data-hub-isolate-instances') === 'true'
            : false; // Default to false if not present

        // Check for form_id attribute and use a default if not present or empty
        const form_id = this.getAttribute('data-data-hub-form-id');
        const final_form_id = form_id ? form_id : Math.random().toString(36).substring(7); // Use form_id if it exists and is not empty, otherwise generate a random one

        const unique_form_id = Math.random().toString(36).substring(7);
        const unique_submit_button_id = Math.random().toString(36).substring(7);

        // Create the most parent container
        const most_parent_div = document.createElement('div');
        most_parent_div.classList.add('data_hub_most_parent_container');

        // Inject Tailwind CSS into most_parent_div
        this.inject_tailwind_css(most_parent_div);

        const form_div = document.createElement('div');
        form_div.classList.add('data_hub_container', 'container', 'max-w-3xl', 'md:max-w-2xl', 'mx-auto', 'mt-0', 'p-4', 'transition-all', 'duration-100', 'ease-in-out');
        form_div.id = unique_form_id;

        const first_form_container = document.createElement('div');
        first_form_container.classList.add('hide-on-return-function');

        const success_div_container = document.createElement('div');
        success_div_container.classList.add('show-on-return-function', 'hidden');

        const success_div = document.createElement('div');
        success_div.classList.add('data_hub_form_success', 'text-lg', 'font-bold', 'text-center');
        success_div.innerHTML = 'Thank you for signing up!';
        success_div_container.appendChild(success_div);

        const form_title_div = document.createElement('div');
        form_title_div.classList.add('data_hub_form_title', 'text-lg', 'font-bold', 'text-left');
        form_title_div.innerHTML = form_title;
        first_form_container.appendChild(form_title_div);

        const form_description_div = document.createElement('div');
        form_description_div.classList.add('data_hub_form_description', 'text-md', 'text-left');
        form_description_div.innerHTML = form_description;
        first_form_container.appendChild(form_description_div);

        const form = document.createElement('form');
        form.classList.add('data_hub_form');

        const fields = this.parse_user_fields(user_fields);

        let set_of_form_inputs_added = new Set();

        this.create_form_fields(form, fields, allow_prefill, set_of_form_inputs_added);

        // Only add sms_optin if the cell_phone field is not already in the user_fields
        if (show_sms_optin && !set_of_form_inputs_added.has('cell_phone')) {
            this.add_sms_optin(form, set_of_form_inputs_added);
        }

        const submit_button = document.createElement('button');
        submit_button.name = 'submit_button';
        submit_button.id = unique_submit_button_id;
        submit_button.classList.add('data_hub_submit_form', 'w-full', 'px-6', 'py-2', 'bg-primary-brand-color', 'text-white', 'rounded-md',
            'hover:bg-primary-brand-color-700', 'focus:bg-primary-brand-color-700', 'active:bg-primary-brand-color-700', 'transition-all',
            'duration-100', 'ease-in-out', 'text-center');
        submit_button.innerHTML = form_submit_button_text;

        form.appendChild(submit_button);

        let this_page_url_with_no_url_params = window.location.href.split('?')[0] || null;

        let user_data_handlebars = {};
        fields.forEach(field => {
            user_data_handlebars[field.name] = `{{user_data.${field.name}}}`;
        });

        const callback_function_after_init = (this_data_hub_instance) => {
            if (!isolate_data_hub_instances && !window.data_hub_instance) {
                window.data_hub_instance = this_data_hub_instance;
            }
        };

        // Manage data_hub_instance based on isolation setting
        let this_data_hub_instance;
        if (isolate_data_hub_instances || !window.data_hub_instance) {
            // Initialize a new instance
            this_data_hub_instance = new data_hub({
                target: most_parent_div,
                forms_domain: "https://action-two-dot-daily-kos-beta.uc.r.appspot.com",
                get_iterable_user_data_via_hmac_key: true,
                theme: {
                    primary_brand_color: {
                        100: "#fcf1e6",
                        200: "#f8d6b4",
                        300: "#f4bb82",
                        400: "#f0a050",
                        500: "#ec851e",
                        600: "#ea7806", // 600 is primary brand color daily-kos orange is ea7806
                        700: "#b55904", // the dark from their site b55904
                        800: "#6c3502",
                        900: "#241100",
                    },
                    contrast_brand_color: "#0678ea", // "#0678ea" is a nice blue
                    highlight_brand_color: {
                        100: "#fcfae6",
                        600: "#ead306",
                        900: "#fcfae6"
                    },
                    grey_brand_color: {
                        200: "#F5F5F5",
                        600: "#302E2E",
                        800: "#131313"
                    }
                }
            }, () => callback_function_after_init(this_data_hub_instance));
        } else {
            console.log('Using existing data_hub instance:', window.data_hub_instance);
            this_data_hub_instance = window.data_hub_instance;
        }

        const this_return_function = () => {
            first_form_container.classList.add('hidden');
            success_div_container.classList.remove('hidden');
        };

        // Add form elements to form_div
        first_form_container.appendChild(form);
        form_div.appendChild(first_form_container);
        form_div.appendChild(success_div_container);

        // Add form_div into most_parent_div
        most_parent_div.appendChild(form_div);
        const this_form_container = most_parent_div;

        let form_settings = {
            form_container: this_form_container, // Use the actual form element
            submit_button: submit_button,
            allow_prefill: true,
            return_function: this_return_function,
            metadata: {
                "form_id": final_form_id,
            },
            returns: { "user_data": "{{user_data}}" },
            events: [
                {
                    "verb": "i_api",
                    "data": {
                        "api_method": "POST",
                        "api_endpoint": "users/updateSubscriptions",
                        "i_api_json": {
                            "email": "{{user_data.email}}",
                            "dataFields": user_data_handlebars
                        }
                    }
                },
                {
                    "verb": "i_api",
                    "data": {
                        "api_method": "POST",
                        "api_endpoint": "events/track",
                        "i_api_json": {
                            "email": "{{user_data.email}}",
                            "eventName": "action",
                            "dataFields": {
                                "email": "{{user_data.email}}",
                                "action_source": "data_hub_form",
                                "form_id": final_form_id,
                                "form_title": form_title,
                                "form_description": form_description,
                                "page_url": this_page_url_with_no_url_params,
                            },
                            "createNewFields": true,
                            "campaignId": this_data_hub_instance.iterable_campaign_id || null,
                            "templateId": this_data_hub_instance.iterable_template_id || null
                        }
                    }
                }
            ]
        };

        form_settings.events[1].data.i_api_json.dataFields = { ...form_settings.events[1].data.i_api_json.dataFields, ...user_data_handlebars };

        if (!window.data_hub_form) {
            console.error('data_hub_form is not loaded. Ensure data_hub_form.js is included before this script.');
            return;
        }

        console.log('Form settings:', form_settings);

        const data_hub_form = window.data_hub_form;
        const this_form_instance = new data_hub_form({
            data_hub_instance: this_data_hub_instance,
            target: most_parent_div
        });
        this_form_instance.init(form_settings);

        // Clear and attach the generated form structure to the Shadow DOM
        this.shadowRoot.innerHTML = '';
        this.shadowRoot.appendChild(most_parent_div);
    }

    parse_user_fields(user_fields) {
        const field_list = user_fields.split(',');
        return field_list.map(field => {
            const [name, size] = field.split(':');
            return { name, size: this.get_tailwind_size_class(size) };
        });
    }

    get_tailwind_size_class(size) {
        switch (size) {
            case '1/3':
                return 'w-1/3';
            case 'full':
                return 'w-full';
            default:
                return 'w-full'; // default if no valid size is found
        }
    }

    create_form_fields(form, fields, allow_prefill, set_of_form_inputs_added) {
        let current_row;
        fields.forEach((field, index) => {
            if (white_list_outgoing_person_fields.includes(field.name)) {

                //if already in set_of_form_inputs_added skip it
                if (set_of_form_inputs_added.has(field.name)) {
                    return;
                }

                set_of_form_inputs_added.add(field.name);

                if (index === 0 || field.size === 'w-full') {
                    current_row = document.createElement('div');
                    current_row.classList.add('flex', 'flex-wrap', '-mx-2', 'mb-4');
                    form.appendChild(current_row);
                }

                const input_div = document.createElement('div');
                input_div.classList.add('px-2', field.size);

                const input = document.createElement('input');
                input.type = user_data_form_field_types[field.name] || 'text';
                input.name = field.name;
                if (allow_prefill) input.dataset.allowDataHubPrefill = 'true';
                input.autocomplete = html_input_autocomplete_types_by_user_data_key_name[field.name] || '';
                input.placeholder = user_data_form_field_placeholders[field.name] || '';
                input.classList.add('p-2', 'border', 'border-grey-brand-color-400', 'rounded-md', 'w-full');

                input_div.appendChild(input);
                current_row.appendChild(input_div);
            }
        });
    }

    add_sms_optin(form, set_of_form_inputs_added) {
        // Check if cell_phone is already in set_of_form_inputs_added, skip adding sms_optin if so
        if (set_of_form_inputs_added.has('cell_phone')) {
            console.log('Skipping sms_optin because cell_phone is already added.');
            return;
        }

        const sms_optin_div = document.createElement('div');
        sms_optin_div.classList.add('flex', 'flex-wrap', '-mx-2', 'mb-4');
        const cell_phone_div = document.createElement('div');
        cell_phone_div.classList.add('lg:w-1/2', 'md:w-1/2', 'px-2', 'w-full');
        const cell_phone_input = document.createElement('input');
        cell_phone_input.type = 'tel';
        cell_phone_input.classList.add('w-full', 'p-2', 'border', 'border-grey-brand-color-400', 'rounded-md');
        cell_phone_input.placeholder = 'Cell Phone';
        cell_phone_input.name = 'cell_phone';
        cell_phone_input.autocomplete = 'tel';
        cell_phone_input.dataset.allowDataHubPrefill = 'true';
        cell_phone_input.dataset.removeForUnsub = 'true';
        cell_phone_div.appendChild(cell_phone_input);

        // add sms_optin checkbox, prechecked, with a label of "I would like to receive text messages"
        const sms_optin_checkbox_div = document.createElement('div');
        sms_optin_checkbox_div.classList.add('lg:w-1/2', 'md:w-1/2', 'px-2', 'w-full');
        const sms_optin_checkbox = document.createElement('input');
        sms_optin_checkbox.type = 'checkbox';
        sms_optin_checkbox.name = 'sms_optin';
        sms_optin_checkbox.checked = true;
        sms_optin_checkbox.dataset.allowDataHubPrefill = 'true';
        sms_optin_checkbox.dataset.removeForUnsub = 'true';
        const sms_optin_label = document.createElement('label');
        sms_optin_label.innerHTML = 'I would like to receive text messages';
        sms_optin_label.htmlFor = 'sms_optin';
        sms_optin_checkbox_div.appendChild(sms_optin_checkbox);
        sms_optin_checkbox_div.appendChild(sms_optin_label);
        
        //append the cell phone and sms optin checkbox to the sms_optin_div
        sms_optin_div.appendChild(cell_phone_div);
        sms_optin_div.appendChild(sms_optin_checkbox_div);

        form.appendChild(sms_optin_div);
    }
}

customElements.define('data-hub-signup-form', data_hub_signup_form);

document.addEventListener('DOMContentLoaded', () => {
    if (window.data_hub) {
        document.querySelectorAll('data-hub-signup-form').forEach(form => form.connectedCallback());
    } else {
        console.error('data_hub is not loaded. Ensure data_hub.js is included before this script.');
    }
});

window.data_hub_signup_form = data_hub_signup_form;
